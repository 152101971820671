

import { CF2Component } from 'javascript/lander/runtime'

export default class TextAreaV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    const textarea = this.element.querySelector('.elTextarea');
    const label = this.element.querySelector('.elLabel');

    this.addTextareaListeners(textarea)
    this.labelDisplayer(textarea.value)
    this.initialTextareaCheck(textarea)
  }

  labelDisplayer(value){
    if (value && value != '') {
      this.element.classList.add('hasValue')
    } else {
      this.element.classList.remove('hasValue')
    }
  }

  initialTextareaCheck(textarea){
    // Check if textarea has value by default
    window.addEventListener('load', () => {
      setTimeout(() => {
        if (textarea.value) {
          this.element.classList.add('hasValue');
        }
      }, 10)
    })
  }

  addTextareaListeners(textarea, re){
    // Add class 'elInputFocused' when textarea is focused
    textarea.addEventListener('focus', () => {
      this.element.querySelector('[data-input-status-type]').innerHTML = ''
      this.element.classList.remove("elInputValid")
      this.element.classList.remove("elInputError")
      this.element.classList.remove("elInputWarning")
      this.element.classList.add('elInputFocused');
    })
    // Handle On blur event to validate textarea and add/remove class - 'hasValue'
    textarea.addEventListener('blur', () => {
      this.element.classList.remove('elInputFocused');
      if (textarea.value) {
        this.element.classList.add('hasValue');
      } else {
        this.element.classList.remove('hasValue');
      }
      if (textarea.classList.contains('required1') && textarea.value === '') {
        return
      }
    })
  }



}

window["TextAreaV1"] = TextAreaV1

